// Start - Support chat scripts
var supportLink = $("#support-link");
var supportBar = $(".support-bar");
var supportClose = $("#support-close");

supportLink.click(function() {
    supportBar.show("slow");
});

supportClose.click(function() {
    supportBar.hide("slow");
});
// End - Support chat scripts